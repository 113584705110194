<template>
    <CContainer fluid>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast :key="'toast' + toast.text + index" :show='true'
                    :header="toast.header === '' ? null : toast.header">
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CRow>
            <CCol class='col-12 col-xl-6 col-md-12'>
                <CCard>
                    <CCardHeader>
                        <strong>Kategori Kapak Fotoğrafı</strong>
                    </CCardHeader>
                    <CCardBody>

                        <CRow class='sm-12 ml-1 justify-content-center'>
                            <croppa v-model='croppa' :width='256' :height='256' :quality='2'
                                :placeholder="'Fotoğraf Yüklemek İçin Tıklayın'" :placeholder-font-size='16'>
                            </croppa>
                        </CRow>
                        <CRow class='justify-content-center'>
                            <small>
                                Minimum 128x128 boyutunda fotoğraf yükleyiniz.
                            </small>
                        </CRow>
                        <CRow>
                            <CCol class='mt-5 col-12 d-flex justify-content-center'>
                                <div v-if='croppa.hasImage && croppa.hasImage()'>
                                    <VueLoadingButton
                                        style='background-color: #26994D; font-size: 14px; background-size: auto'
                                        aria-label='Post message' class='button' @click.native='updateCategoryPhoto'
                                        :loading='isLoading' :styled='isStyled'>Fotoğrafı Yükle
                                    </VueLoadingButton>
                                </div>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol class='col-12 col-xl-6 col-md-12'>
                <CCard>
                    <CCardHeader>
                        <strong>Kategoriyi Düzenle</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='col-12 mt-1'>
                                <CInput type='text' label='Kategori Başlığı' v-model='categoryTitle'>
                                    <template #prepend-content>
                                        <CIcon name='cil-font' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4' v-if='showCategoryTitleSaveButton' color='success'
                                    @click='updateCategoryTitle'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class='col-12'>
                                <CInput type='text' label='Kategori Açıklaması' v-model='categoryDescription'>
                                    <template #prepend-content>
                                        <CIcon name='cil-font' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4' v-if='showCategoryDescriptionSaveButton' color='success'
                                    @click='updateCategoryDescription'>
                                    Kaydet
                                </CButton>
                            </CCol>
                            <CCol class='col-12 mt-1'>
                                <CInput type='text' label='Slug' v-model='categorySlug'>
                                    <template #prepend-content>
                                        <CIcon name='cil-font' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4' v-if='showCategorySlugSaveButton' color='success'
                                    @click='updateCategorySlug'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow class='col-12 mt-4'>
                            <CCol col='12' md='5' class='justify-content-end'>
                                <CButton @click='deleteWordTutorialCategoryAlert' block color='danger'>Kategoriyi Sil
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol class='col-12'>
                <CCard>
                    <CRow class='mt-3 ml-3 mr-3 justify-content-between'>
                        <CCol class='col-12 col-xl-2 col-md-6 mb-1'>
                            <CButton @click='showAlert' block color='danger'>Tümünü Sil</CButton>
                        </CCol>
                        <CCol class='col-12 col-xl-3 col-md-6 mb-1'>
                            <CButton @click='addModal = true' block color='success'>Toplu Kelime Yükleme</CButton>
                        </CCol>
                        <CCol class='col-12 col-xl-3 col-md-6 mb-1 justify-content-end'>
                            <CButton @click='newWordModal = true' block color='success'>Yeni Kelime Ekle</CButton>

                        </CCol>
                    </CRow>
                    <CCardHeader>
                        <strong>Kelime Listesi</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='col-6'>
                                <CInput label='Arama: ' :horizontal="{ label: 'w-auto', input: 'w-100' }"
                                    v-debounce:600ms='changeFilterValue' />
                            </CCol>
                        </CRow>
                        <CDataTable class='mb-0 table-outline' hover :items='tableItems' :fields='tableFields'
                            head-color='light' :loading='isDataLoading' :items-per-page='itemsPerPage'>
                            <template #no-items-view>
                                <CRow>
                                    <CCol class='col-12 font-3xl text-center'>
                                        <CIcon name='cil-x' />
                                        Veri Bulunamadı
                                    </CCol>
                                </CRow>
                            </template>
                            <td slot='id' slot-scope='{item}'>
                                <div>{{ item.id }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='subtitles' slot-scope='{item}'>
                                <div v-for='(subtitle, index) in item.subtitles' :key='index'>
                                    <CInput horizontal :label='subtitle.languageCode' style='width:200px'
                                        v-model='subtitle.url' disabled></CInput>
                                </div>
                            </td>

                            <td slot='translations' slot-scope='{item}'>
                                <div v-for='(translation, index) in item.translations' :key='index'>
                                    <CInput horizontal :label='translation.languageCode' style='width:200px'
                                        v-model='translation.content' disabled></CInput>
                                </div>
                            </td>

                            <td slot='youtubeVideoUrl' slot-scope='{item}'>
                                <div class='clearfix'>
                                </div>
                                <a target='_blank' :href='item.youtubeVideoUrl'>{{ item.youtubeVideoUrl }}</a>
                            </td>
                            <td slot='manage' slot-scope={item}>
                                <CButton @click='showEditModal(item.id)' color='success' class='mr-2 mb-1'>
                                    <CIcon name='cil-pencil' />
                                </CButton>
                                <CButton @click='uploadSubtitleFile(item.id)' class='mb-1' color='success'>
                                    <CIcon name='cilArrowCircleTop' />
                                </CButton>&nbsp;
                                <CButton @click='showDeleteAlert(item.id)' class='mb-1' color='danger'>
                                    <CIcon name='cil-x' />
                                </CButton>
                            </td>
                        </CDataTable>
                        <CPagination v-if='totalPages !== 0' :pages='totalPages' :active-page='currentPage'
                            :activePage.sync='currentPage' align='center' class='mt-3'>
                        </CPagination>
                        <CRow class='mt-3'>
                            <CCol col='6' md='4'>
                                <CButton @click='showAlert' block color='danger'>Tümünü Sil</CButton>
                            </CCol>
                            <CCol col='6' md='4'>
                                <CButton @click='addModal = true' block color='success'>Toplu Kelime Yükleme</CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <!-- TOPLU KELİME YÜKLEME MODALI -->
        <CModal title='Kelime Düzenleme' color='Info' :show.sync='editModal' v-if='currentItem'>
            <CContainer fluid>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Kategori' type='text' description='ex. Kategori' label='Türkçe Kelime'
                            v-model='wordContentTurkish'>
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='Category' type='text' description='ex. Category' label='İngilizce Kelime'
                            v-model="wordContentEnglish">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='https://youtube.com/link' type='text'
                            description='ex. https://youtube.com/link' label='Youtube Video Linki'
                            v-model='currentItem.youtubeVideoUrl'>
                            <template #prepend-content>
                                <CIcon name='cil-link-alt' />
                            </template>
                        </CInput>
                        <CButton class='mb-4' v-if='showYoutubeLinkSaveButton' color='success' @click='updateYoutubeUrl'>
                            Kaydet
                        </CButton>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput disabled placeholder='kategorileri-yonet' type='text' label='Slug'
                            v-model='currentItem.slug'>
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class="col-lg-6 col-md-6 col-12">
                        <CSelect vertical label="Aksan Seçimi" :value.sync="selectedAccent" :options="accentOptions"
                            placeholder="Seçim Yapınız" custom />
                    </CCol>
                    <CCol class="col-lg-6 col-md-6 col-12">
                        <CSelect vertical label="Dil Seviyesi" :value.sync="selectedLanguage" :options="languageOptions"
                            placeholder="Seçim Yapınız" custom />
                    </CCol>
                </CRow>
                <!-- <CRow>
                    <CCol class='col-6 mt-4'>
                        <CInputFile label='İngilizce Altyazı' vertical description='Sadece SRT Formatı' />
                    </CCol>
                    <CCol class='col-6  mt-4'>
                        <CInputFile label='Türkçe Altyazı' vertical description='Sadece SRT Formatı' />
                    </CCol>
                </CRow> -->
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='editModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='updateWord(currentItem.id)'>Değişiklikleri Kaydet</CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>
        <!-- NEW WORD MODAL -->
        <CModal title='Yeni Kelime Ekle' color='Info' :show.sync='newWordModal'>
            <CContainer fluid>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Kategori' type='text' description='ex. Kategori' label='Türkçe Kelime'
                            v-model="wordContentTurkish">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='Category' type='text' description='ex. Category' label='İngilizce Kelime'
                            v-model="wordContentEnglish">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='https://youtube.com/link' type='text'
                            description='ex. https://youtube.com/link' label='Youtube Video Linki'
                            v-model='wordYoutubeVideoUrl'>
                            <template #prepend-content>
                                <CIcon name='cil-link-alt' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='kategorileri-yonet' type='text' label='Slug' v-model='wordSlug'>
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class="col-lg-6 col-md-6 col-12">
                        <CSelect vertical label="Aksan Seçimi" :value.sync="selectedAccent" :options="accentOptions"
                            placeholder="Seçim Yapınız" custom />
                    </CCol>
                    <CCol class="col-lg-6 col-md-6 col-12">
                        <CSelect vertical label="Dil Seviyesi" :value.sync="selectedLanguage" :options="languageOptions"
                            placeholder="Seçim Yapınız" custom />
                    </CCol>
                </CRow>
                <!-- <CRow>
                    <CCol class='col-6 mt-4'>
                        <CInputFile label='İngilizce Altyazı' vertical description='Sadece SRT Formatı' />
                    </CCol>
                    <CCol class='col-6  mt-4'>
                        <CInputFile label='Türkçe Altyazı' vertical description='Sadece SRT Formatı' />
                    </CCol>
                </CRow> -->
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='newWordModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='addWord' :loading='isLoading'>Değişiklikleri Kaydet
                            </CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>
        <CModal title='Kelime Düzenleme' color='Info' :show.sync='addModal'>
            <CRow>
                <CCol class='col-12  mt-4'>
                    <CInputFile label='Kelime Dosyası' vertical description='Sadece .xslx veya .csv dosya' />
                </CCol>
            </CRow>
            <template v-slot:footer>
                <CRow>
                    <CCol class='col-6'>
                        <CButton @click='addModal = false' block color='dark'>Vazgeç</CButton>
                    </CCol>
                    <CCol class='col-6'>
                        <CButton block color='info'>Değişiklikleri Kaydet</CButton>
                    </CCol>
                </CRow>
            </template>
        </CModal>
        <CModal title='Kelime Düzenleme' color='Info' :show.sync='subtitleModal'>
            <CRow>
                <CCol class='col-12  mt-4'>                 
                        <CSelect label="Dil Seçin" v-model="subtitleLanguageCode" vertical :options="options"
                  placeholder="Dil Seçin" />
                </CCol>
            </CRow>
            <CRow>
                <CCol class='col-12  mt-4'>
                    <CInputFile label='Kelime Dosyası' @change="handleFileUpload($event)" vertical
                        description='Sadece .srt dosya' />
                </CCol>
            </CRow>
            <template v-slot:footer>
                <CRow>
                    <CCol class='col-6'>
                        <CButton @click='subtitleModal = false' block color='dark'>Vazgeç</CButton>
                    </CCol>
                    <CCol class='col-6'>
                        <CButton block color='info' @click='subtitleSave' :disabled="isDisabled">Değişiklikleri Kaydet</CButton>
                    </CCol>
                </CRow>
            </template>
        </CModal>
    </CContainer>
</template>

<script>
import { WordTutorialAdministration, WordTutorialCategoryAdministration,WordTutorialSubtitleAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import router from '@/router'

export default {
    name: 'ManageCategories',
    components: {
        VueLoadingButton,
    },
    mounted() {
        this.loadWordList()
        this.loadCategoryInfo()
    },
    watch: {
        filterValue() {
            this.loadCategoryInfo()
        },
        currentPage() {
            this.loadCategoryInfo()
        },
        itemsPerPage() {
            this.loadCategoryInfo()
        },
    },
    data() {
        return {   
            isDisabled:false,         
      options: ['tr', 'en'],
            subtitleLanguageCode:'',
            file: '',
            filterValue: '',
            currentPage: 1,
            wordTutorialId: null,
            itemsPerPage: 10,
            totalPages: 1,
            YoutubeUrlFromServer: '',
            isStyled: true,
            isLoading: false,
            newCoverPhoto: '',
            croppa: {},
            categorySlug: '',
            categorySlugFromServer: '',
            categoryDescription: '',
            categoryDescriptionFromServer: '',
            categoryTitle: '',
            categoryTitleFromServer: '',
            currentItem: null,
            currentWordItem: null,
            isDataLoading: false,
            editModal: false,
            addModal: false,
            subtitleModal: false,
            newWordModal: false,
            wordYoutubeVideoUrl: '',
            wordSlug: '',
            wordContentTurkish: '',
            wordContentEnglish: '',
            accentOptions: [
                { value: 'NATIVE_US', label: 'Amerikan Aksanı' },
                { value: 'NATIVE_BRITISH', label: 'İngiliz Aksanı' },
                { value: 'TURKISH', label: 'Türkçe' },
                { value: 'OTHER', label: 'Diğer Diller' }
            ],
            languageOptions: [
                { value: 'BEGINNER', label: 'Giriş' },
                { value: 'INTERMEDIATE', label: 'Orta' },
                { value: 'ADVANCED', label: 'İleri' },
            ],
            selectedAccent: '',
            selectedLanguage: '',
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'subtitles', label: 'Altyazı' },
                { key: 'translations', label: 'Çeviri' },
                { key: 'youtubeVideoUrl', label: 'Youtube Linki' },
                { key: 'manage', label: 'Yönet' },
            ],
        }
    },
    computed: {
        showYoutubeLinkSaveButton() {
            return this.currentItem.youtubeVideoUrl !== this.YoutubeUrlFromServer
        },
        showCategoryTitleSaveButton() {
            return this.categoryTitle !== this.categoryTitleFromServer
        },
        showCategoryDescriptionSaveButton() {
            return this.categoryDescription !== this.categoryDescriptionFromServer
        },
        showCategorySlugSaveButton() {
            return this.categorySlug !== this.categorySlugFromServer
        },
    },
    methods: {
        async uploadSubtitleFile(data) {
            let id = data;
            this.wordTutorialId = Number(id);
            this.subtitleModal = !this.subtitleModal;
        },
        handleFileUpload(event) {
            this.file =  event[0];
        },
        async subtitleSave() {
            this.isDisabled = true;
            const result = await WordTutorialSubtitleAdministration.addSubtitleToWordTutorial(this.file, this.wordTutorialId, this.subtitleLanguageCode)
            this.subtitleModal = !this.subtitleModal;
            this.loadWordList();
            console.log(result);
        },
        async addWord() {
            this.isLoading = true
            try {
                const id = parseInt(this.$route.params['id'])
                let translations = [];

                if (this.wordContentTurkish) {
                    translations.push({
                        "languageCode": "tr",
                        "isRtlLanguage": false,
                        "content": this.wordContentTurkish
                    })
                }

                if (this.wordContentEnglish) {
                    translations.push({
                        "languageCode": "en",
                        "isRtlLanguage": false,
                        "content": this.wordContentEnglish
                    })

                }

                const result = await WordTutorialAdministration.createWordTutorial(translations, id, this.wordSlug, this.wordYoutubeVideoUrl, this.selectedAccent, this.selectedLanguage)
                console.log(result);
                const newWord = {
                    id: result.data.createdWordTutorialId,
                    youtubeVideoUrl: this.wordYoutubeVideoUrl,
                    translations: translations,
                }
                this.isLoading = false
                this.newWordModal = false;
                this.tableItems = [newWord, ...this.tableItems]
                this.$toast.success('Yeni kelime Başarıyla Eklendi!')
                this.wordYoutubeVideoUrl = ''
                this.wordSlug = ''
                this.wordContentTurkish = ''
                this.wordContentEnglish = ''
                this.selectedAccent = ''
                this.selectedLanguage = ''
            }
            catch (e) {
                throw new e
            }
        },
        async updateWord(_id) {
            this.isLoading = true
            try {
                const id = parseInt(this.$route.params['id'])
                let translations = [];

                if (this.wordContentTurkish) {
                    translations.push({
                        "languageCode": "tr",
                        "isRtlLanguage": false,
                        "content": this.wordContentTurkish
                    })
                }

                if (this.wordContentEnglish) {
                    translations.push({
                        "languageCode": "en",
                        "isRtlLanguage": false,
                        "content": this.wordContentEnglish
                    })

                }

                const result = await WordTutorialAdministration.updateWordTutorial(translations, this.currentItem.id, id, this.currentItem.slug, this.wordYoutubeVideoUrl, this.selectedAccent, this.selectedLanguage)
                console.log(result);

                const index = this.tableItems.findIndex(j => j.id === _id)
                this.tableItems[index].translations = translations
                this.tableItems[index].youtubeVideoUrl = this.wordYoutubeVideoUrl
                this.tableItems[index].accentType = this.selectedAccent
                this.tableItems[index].languageLevel = this.selectedLanguage

                this.isLoading = false
                this.editModal = false;
                this.$toast.success('kelime başarıyla güncellendi!')
                this.wordYoutubeVideoUrl = ''
                this.wordSlug = ''
                this.wordContentTurkish = ''
                this.wordContentEnglish = ''
                this.selectedAccent = ''
                this.selectedLanguage = ''
            }
            catch (e) {
                throw new e
            }
        },
        async updateYoutubeUrl() {
            await WordTutorialAdministration.changeYoutubeUrlOfTheWordTutorial(this.currentItem.id, this.currentItem.youtubeVideoUrl)
            this.YoutubeUrlFromServer = this.currentItem.youtubeVideoUrl
        },
        blobConvert() {
            return this.croppa.promisedBlob()
        },
        async updateCategoryPhoto() {
            this.isLoading = true
            const id = parseInt(this.$route.params['id'])
            const convertedImage = await this.blobConvert()
            try {
                await WordTutorialCategoryAdministration.changeCoverPhotoOfTheWordTutorialCategory(convertedImage, id)
                this.croppa.remove()
                this.$toast.success('Fotoğraf Başarıyla Güncellendi!')
            } catch (e) {
                throw new e
            }
            this.isLoading = false
        },
        showEditModal(id) {
            this.currentItem = this.tableItems.find(x => x.id === id)
            this.wordContentTurkish = this.currentItem?.translations[0].content
            this.wordContentEnglish = this.currentItem?.translations[1].content
            this.wordYoutubeVideoUrl = this.currentItem?.youtubeVideoUrl
            this.wordSlug = this.currentItem?.slug
            this.selectedAccent = this.accentOptions.find(x => x.value === this.currentItem.accentType)?.value
            this.selectedLanguage = this.languageOptions.find(x => x.value === this.currentItem.languageLevel)?.value
            this.editModal = !this.editModal
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        showDeleteAlert(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.tableItems = this.tableItems.filter(r => r.id !== id)
                    await WordTutorialAdministration.deleteWordTutorial(id)
                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showAlert() {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        deleteToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },

        async loadCategoryInfo() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            try {
                const result = await WordTutorialCategoryAdministration.listWordTutorialCategoriesForAdministration(this.currentPage, this.itemsPerPage, this.filterValue)
                this.totalPages = result.data.pagination.totalPages
                const categoryInfo = result.data.wordTutorialCategoryList.find(f => f.id === id)

                this.categoryTitle = categoryInfo.title
                this.categoryTitleFromServer = categoryInfo.title

                this.categoryDescription = categoryInfo.description
                this.categoryDescriptionFromServer = categoryInfo.description

                this.categorySlug = categoryInfo.slug
                this.categorySlugFromServer = categoryInfo.slug
            } catch (e) {
                this.toasts.push('Veriler Getirilemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        changeFilterValue(newValue) {
            this.filterValue = newValue
        },
        async updateCategoryTitle() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            try {
                await WordTutorialAdministration.createWordTutorial()
                await WordTutorialCategoryAdministration.changeTitleOfTheWordTutorialCategory(id, this.categoryTitle)
                this.categoryTitleFromServer = this.categoryTitle
            } catch (e) {
                this.toasts.push('Veri Güncellenemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        async updateCategoryDescription() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            try {
                await WordTutorialCategoryAdministration.changeDescriptionOfTheWordTutorialCategory(id, this.categoryDescription)
                this.categoryDescriptionFromServer = this.categoryDescription
            } catch (e) {
                this.toasts.push('Veri Güncellenemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        async updateCategorySlug() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            try {
                await WordTutorialCategoryAdministration.changeSlugOfTheWordTutorialCategory(id, this.categorySlug)
                this.categorySlugFromServer = this.categorySlug
            } catch (e) {
                this.toasts.push('Veri Güncellenemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        async loadWordList() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            const result = await WordTutorialAdministration.listWordTutorialsForAdministration(id)
            this.tableItems = result.data.wordTutorials
            this.isDataLoading = false
        },
        deleteWordTutorialCategoryAlert() {
            this.$swal({
                title: 'Kategoriyi Silmek istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        await WordTutorialCategoryAdministration.deleteWordTutorialCategory(id)
                        await this.$swal.fire({
                            title: 'Kategori Silindi!',
                            text: 'Kategori Başarıyla Silindi!',
                            icon: 'success',
                            timer: 2000,
                            willClose() {
                                router.push({ path: '/kategoriler' })
                            },
                        })
                    } catch (e) {
                        this.toaster.push('İşlem Gerçekleştirilemedi', 'Hata')
                    }
                }
            })
        },
    },
}
</script>


